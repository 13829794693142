export const GRAPHS = {
    MOLLIER: 'Mollier',
    CIBS: 'CIBS'
};

export const DEFAULT_AXIS_VALUES = {
    minMoisture: 0.0,
    maxMoisture: 25,
    minTemperature: -20,
    maxTemperature: 35
};

export const UNIT_SYSTEM = {
    METRIC: 'Metric',
    IMPERIAL: 'Imperial'
};

export const LANGUAGES = {
    SE: 'sv-SE',
    EN: 'en-GB',
    US: 'en-US'
};

export const UNIT_ID = {
    globalTemperature: 'globalTemperature',
    atmPressure: 'atmPressure',
    density: 'density',
    pointTemperature: 'pointTemperature',
    relativeHumidity: 'relativeHumidity',
    moistureContent: 'moistureContent',
    enthalpy: 'enthalpy',
    pointAirflowToNext: 'pointAirflowToNext',
    power: 'linePower',
    sensiblePower: 'sensiblePower',
    water: 'water'
};

export const PRINT_ORIENTATIONS = {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape'
};

export const AIR_HUMIDITY = {
    RELATIVE_HUMIDITY: 'relativeHumidity',
    WET_BULB: 'wetBulb'
};

export const SEASONS = {
    SUMMER: 'summer',
    WINTER: 'winter'
}

export const COLORS = {
    green: 'rgb(51, 156, 100)',
    blue: 'rgb(106, 129, 242)',
    lavender: 'rgb(95, 113, 181)',
    pink: 'rgb(199, 47, 129)',
    red: 'rgb(232, 79, 72)',
    yellow: 'rgb(255, 206, 62)',
    purple: 'rgb(136, 75, 144)',
    orange: 'rgb(245, 137, 77)'
}
